$ ->
  storageItemName = 'headerNotificationFilter'
  baseUrl = '/mypage/reads'
  notificationsUrl = "#{baseUrl}/notifications"
  readQuestionsUrl = "#{baseUrl}/read_questions"
  $checkbox = $('#switch')
  $label = $('#header .toggle-switch label')
  $labelText = $('#header .toggle-switch-wrapper .label-text')

  urlWithFilterParam = (url) ->
    filter = if getStorageValue() == '1' then 'unread' else null
    return "#{url}?filter=#{filter}" if filter
    url

  initCheckboxState = ->
    return $checkbox.prop 'checked', true if getStorageValue() == '1'
    $checkbox.prop 'checked', false

  getStorageValue = ->
    return '1' if localStorage.getItem(storageItemName) == '1'
    '0'

  clickLabel = ->
    $checkbox.prop 'checked', (_i, currentValue) ->
      return !currentValue
    $checkbox.trigger 'change'
    # NOTE: ドロップダウンメニューを閉じないようにする対応
    return false

  $label.on 'click', ->
    clickLabel()

  $labelText.on 'click', ->
    clickLabel()

  $checkbox.on 'change', ->
    checked = if $checkbox.prop 'checked' then '1' else '0'
    localStorage.setItem storageItemName, checked
    $.ajax urlWithFilterParam(notificationsUrl)

  # NOTE: 初期状態
  initCheckboxState()

  # NOTE: 通知アイコンクリックで question を既読にする機能
  # TODO: 通知アイコンクリックで通知ドロップダウンを閉じる
  $(document).on 'click', '.notification-question-read', (ev) ->
    $.post urlWithFilterParam(readQuestionsUrl)

  App.cable.subscriptions.create { channel: "NotificationsChannel" },
    connected: () ->
      $.ajax urlWithFilterParam(notificationsUrl)
    received: (data) ->
      if data.message
        $('.message-notifier').html(data.message).fadeIn(1200).delay(4000).fadeOut("slow")
      if data.notifications
        $('.menu-notifications-content').html(data.notifications)
        $('a.menu-notifications').removeClass('disabled')
      if data.unreads
        $.each data.unreads, (name, count) ->
          badge = $(".icon-notification-#{name}")
          if badge.length > 0
            if count == 0
              badge.hide()
              badge.parents('a.dropdown-notification').removeClass('active')
            else
              count = "10+" if count > 10
              badge.text(count).show()
              badge.parents('a.dropdown-notification').addClass('active')
              if name == 'total'
                if document.title.match(/^\(/)
                  document.title = document.title.replace(/^\([0-9+]+\)/, "(#{count})")
                else
                  document.title = "(#{count}) " + document.title
        $noNotificationsMessageUnread = $('.no-notifications-message > .unread')
        if data.unreads.total == 0
          $noNotificationsMessageUnread.show()
        else
          $noNotificationsMessageUnread.hide()
