jQuery ->
  class ColsedCookie
    IGNORE_ALERT_KEY = 'close_sign_up_modal_count'
    MS_OF_3_DAYS = 259200000 #  3 * 24 * 60 * 60 * 1000  ms
    destory: ->
      Cookies.remove(IGNORE_ALERT_KEY)
    setup: ->
      secure = location.protocol == 'https:'
      in30Minutes = new Date(new Date().getTime() + MS_OF_3_DAYS)
      count = Cookies.get(IGNORE_ALERT_KEY) || 0
      count = parseInt(count) + 1
      Cookies.set(IGNORE_ALERT_KEY, count, { expires: in30Minutes, secure: secure, sameSite: 'strict' })
    get: ->
      Cookies.get(IGNORE_ALERT_KEY) || 0

  return if $('#modal-signup').length == 0
  return if $('#show_signup_mordal').val() != 'true'

  already_shown_in_this_page = false
  cookie = new ColsedCookie()

  $(document).on 'scroll', () ->
    half = $(document).height() / 2
    if $(window).scrollTop() > half && !already_shown_in_this_page && cookie.get() < 3
      $('#modal-signup').modal('show')
      already_shown_in_this_page = true

  $('#modal-signup').on 'hidden.bs.modal', ->
    cookie.setup()

