$ ->
  if $('#modal-term-agreement').length
    # when any modal is opening
    $('.modal').on 'show.bs.modal', (e) ->
      return e.currentTarget.id == 'modal-term-agreement'

    $('#modal-term-agreement').modal(keyboard: false)
    $('#modal-term-agreement-btn-agree').on 'click', ->
      $('#modal-term-agreement').modal('hide')
      $(this).parents('form').submit()
jQuery
