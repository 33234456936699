$ ->
  $.autoScroll = (selector, offset, speed, callback) ->
    scrollTop = if (selector && $(selector).offset()) then ($(selector).offset().top - (offset || 0)) else 0
    $('body,html').stop().animate({
      scrollTop: scrollTop
    }, speed || 800, 'swing', callback)

  $.onValueChanged = (selector, callback, options = {}) ->
    $(document).on 'change keyup', selector.selector || selector, (e) ->
      if @areaSearchTimeOutId
        clearTimeout @areaSearchTimeOutId

      currentInput = $(e.target).val()
      if currentInput != @previousInput
        @areaSearchTimeOutId = setTimeout((() ->
          @previousInput = currentInput
          callback(e.target, currentInput)
        ).bind(@), options.time || 200)

  $.getUniqueStr = () ->
    return new Date().getTime().toString(16) + Math.floor(1000 * Math.random()).toString(16)

  $.fn.areAllHidden = ->
    !$(this).is((_, elem) -> $(elem).css('display') == 'block')

  $.buttonDisabledSwitch = (button, text = '処理中です', enable = false) ->
    $button = $(button)
    if enable
      $button.removeAttr('disabled').removeClass('disabled').text(text)
    else
      $button.attr('disabled', 'disabled').addClass('disabled').text(text)

  $.addErrorMessage = (message) ->
    $('.header-fixed-message.error').text(message).fadeIn(1200).delay(10000).fadeOut('slow')
    
  $.separate = (val) ->
    String(val).replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')

  $.formatToYen = (amount) ->
    $.separate(amount) + '円'

  correctDayRangeFn = (dateDomName) ->
    year = $("##{dateDomName}_1i").val()
    month = $("##{dateDomName}_2i").val()
    daysOfMonth = new Date(year, month, 0).getDate()
    $day = $("##{dateDomName}_3i")
    selectedDay = Number($day.find('option:selected').val())
    selectedDay = if 1 <= selectedDay && selectedDay <= daysOfMonth then selectedDay else ''
    $day.html('')
    for i in [1..daysOfMonth]
      if i == selectedDay
        $day.append("<option value='#{i}' selected='selected'>#{i}</option>")
      else
        $day.append("<option value='#{i}'>#{i}</option>")
    $day.val(selectedDay)
    
  $.correctDayRange = (dateDomName) ->
    correctDayRangeFn(dateDomName)
    $("##{dateDomName}_1i, ##{dateDomName}_2i").on 'change', ->
      correctDayRangeFn(dateDomName)

  # Copy from lodash: https://github.com/lodash/lodash/blob/master/escapeRegExp.js
  reRegExpChar = /[\\^$.*+?()[\]{}|]/g
  reHasRegExpChar = RegExp(reRegExpChar.source)
  $.escapeRegExp = (string) ->
    if (string && reHasRegExpChar.test(string))
      string.replace(reRegExpChar, '\\$&')
    else
      string
jQuery
