jQuery ->
  $modalAreaSelect = $('#modal-area-select')
  $modalAreaSelectAreas = $modalAreaSelect.find('.areas')
  SEARCH_AREA_MAX_SIZE = 20
  searchArea = (string, escape = true) ->
    $modalAreaSelectAreas.html('')
    string = if string?.length != 0 then string.trim() else ''
    $.get '/pages/render_areas_for_area_selection',
      {regexp: string, max_size: SEARCH_AREA_MAX_SIZE, escape: escape},
      (data) ->
        filterArea(string, data, escape)

  filterArea = (string, data, escape) ->
    isEmptyData = data?.length == 0
    $modalAreaSelectAreas.html(data) unless isEmptyData

    string = if escape then $.escapeRegExp(string) else string
    word_exp = new RegExp(string, "i")
    $modalAreaSelect.find('li:not(.alert li)').each((_, li) ->
      li = $(li)
      if isEmptyData || li.data('text').match(word_exp)
        li.addClass('active')
        li.parents('section:first').show()
      else
        li.removeClass('active')
        if li.parents('ul:first').find('li.active').length == 0
          li.parents('section:first').hide()
    )
    if $modalAreaSelectAreas.find('li.active').length < SEARCH_AREA_MAX_SIZE
      $modalAreaSelectAreas.show()
    else
      $modalAreaSelectAreas.hide()

    if $('.modal-area-list section:visible').length == 0
      $('#form_apply_area').show()
    else
      $('#form_apply_area').hide()

  $areaSearchBox = $('#area_search_box')
  $.onValueChanged('#area_search_box', (_, value) ->
    searchArea(value)
  )

  $(document).on 'click', '.kana-search-list a', (ev) ->
    $areaSearchBox.val($(ev.target).text())
    searchArea("#{$(ev.target).data('regexp')}", false)

  $(document).on 'submit', '#form_apply_area', () ->
    $('#area_name').val($areaSearchBox.val())

  $modalAreaSelect.on 'shown.bs.modal', () ->
    dataLayer.push({
      event: 'analytics',
      eventCategory: 'modal',
      eventAction: 'area-select',
      eventLabel: location.pathname
    })
    $areaSearchBox.focus()
