# NOTE: Google タグ マネージャーのイベント送信
# app/libs/ga4_client.rbによりCookiesにイベントが保存される
# app/views/layouts/_google_tag_manager.html.slimにGoogle タグ マネージャーの埋め込みコードがある

$ ->
  count = Cookies.get('locotabiEventCount')
  Cookies.remove('locotabiEventCount')
  i = 0
  while i < count
    keyName = 'locotabiEvent'+i
    event = Cookies.get(keyName)
    Cookies.remove(keyName)
    if !!event
      dataLayer.push(JSON.parse(event))
    i++
