(($, window) ->
  $.fn.extend clock: (option, args...) ->
    @each ->
      $this = $(this)
      $this.css('height', $this.css('width'))
      date = $this.data('time')

      if date
        date = new Date(date)
      else
        date = new Date

      seconds = date.getSeconds()
      minutes = date.getMinutes()
      hours = date.getHours()

      hands = [
        { hand: 'hours', angle: (hours * 30) + (minutes / 2) },
        { hand: 'minutes', angle: (minutes * 6) },
        { hand: 'seconds', angle: (seconds * 6) }
      ]
      # Loop through each of these hands to set their angle
      for j in [0..hands.length - 1]
        element = $this.find(".#{hands[j]['hand']}:first")[0]
        element.style.webkitTransform = "rotateZ(#{hands[j]['angle']}deg)"
        element.style.transform = "rotateZ(#{hands[j]['angle']}deg)"
        # If this is a minute hand, note the seconds position (to calculate minute position later)
        if hands[j]['hand'] == 'minutes'
          element.parentNode.setAttribute('data-second-angle', hands[j + 1]['angle'])
) window.jQuery, window
