$ ->
  class AlertCookie
    IGNORE_ALERT_KEY = 'ignore_alert'
    MS_OF_30MINUTES = 1800000 # 30 * 60 * 1000 ms

    destory: ->
      Cookies.remove(IGNORE_ALERT_KEY)

    setup: ->
      secure = location.protocol == 'https:'
      in30Minutes = new Date(new Date().getTime() + MS_OF_30MINUTES)
      Cookies.set(IGNORE_ALERT_KEY, 'true', { expires: in30Minutes, secure: secure, sameSite: 'strict' })

    ignore_alert: ->
      Cookies.get(IGNORE_ALERT_KEY) == 'true'

    check_alert: ->
      !this.ignore_alert()

  class AlertForm
    constructor: ($alert_target_form) ->
      @$alert_target_form = $alert_target_form
      @$submit_button = $alert_target_form.find(':submit')
      $parent_modal = $alert_target_form.find('#parent_modal')
      @$parent_modal = $($parent_modal.val()) if $parent_modal.length
      @call_submit = false

    hide: ->
      @$parent_modal.modal('hide') if @$parent_modal

    show: ->
      @$parent_modal.modal(keyboard: false) if @$parent_modal

    enable_submit: ->
      @$submit_button.prop('disabled', false)

    disable_submit: ->
      @$submit_button.prop('disabled', true)

    continue_action: ->
      @$alert_target_form.submit()
      @call_submit = true

    request_data: (check_alert_request = true) ->
      data = @$alert_target_form.serializeArray()
      data.push({name: 'check_alert_request', value: check_alert_request})
      return $.param(data)

    request_url: ->
      @$alert_target_form[0].action

  $alert_container = $('.alert-target-container')
  $alert_modal = $('#modal-alert')
  alert_cookie = new AlertCookie()
  alert_result = null
  alert_form = null
  alert_check_busy = false

  clear_cookie_if_move_to_non_alert_page = ->
    alert_cookie.destory()

  unless $alert_container.length
    clear_cookie_if_move_to_non_alert_page()
    return

  $alert_modal.find('#btn-modal-alert-collect').on 'click', ->
    $alert_modal.modal('hide')
    alert_form.show()

  $alert_modal.find('#btn-modal-alert-continue').on 'click', ->
    alert_cookie.setup()
    alert_form.continue_action()
    $alert_modal.modal('hide')

  set_modal_continue_button = (name) ->
    $alert_modal.find('#btn-modal-alert-continue').text(name)

  $alert_modal.on 'hidden.bs.modal', ->
    alert_form.enable_submit() unless alert_form.call_submit

  $alert_container.on 'keyup','.alert-target-form', ->
    alert_result = null

  show_modal_alert_if_alert_ng = ->
    $.post(alert_form.request_url(), alert_form.request_data())
      .done((data) ->
        alert_result = data.status
        if alert_result == 'error'
          alert_form.hide()
          $alert_modal.modal(keyboard: false)
        else
          alert_form.continue_action()
      )

  notify_alert = ->
    return false if alert_cookie.ignore_alert() || (alert_result == 'ok')
    return true

  $alert_container.on 'submit','.alert-target-form', ->
    return true if notify_alert() == false
    set_modal_continue_button('投稿を完了する')
    alert_form = new AlertForm($(this))
    alert_form.disable_submit()
    show_modal_alert_if_alert_ng()
    return false
